//*******************************************************************************
// 1.0 - HEADLINES
//*******************************************************************************

.title {
	color: $cgp_white;
	font-weight: 700;
	//line-height: 1em;
	//text-transform: uppercase;
	display: block;

	span { color: $cgp_orange; }

	em {
		font-style: normal;
		font-weight: 300;
	}

}



// ##################################################
// ##################################################


// Text styles
.text {
	font-family: $font_primary;
	font-weight: 300;

	& > * {
		@extend %inherit_textstyles;
	}

}


// ##################################################
// ##################################################

// Page text styles
.page-text {
	font-family: $font_primary;
	color: $cgp_white;
	line-height: 1.5em;

	// ##################################################

	//&--large { @extend %page_text; }

	// ##################################################

	&--small {
		@extend %page_text_small;
	}

	// ##################################################

	// Heading classes
	// example: h1, .h1 {@extend %heading_h1;}
	@for $i from 1 through 6 {
		h#{$i}, .h#{$i} {
			@extend %heading_h#{$i};
			font-weight: 700;
		}
	}

	//p, li {@extend %page_text;}

	ul {list-style: disc inside;}

	ol {list-style: decimal inside;}

	blockquote {
		font-style: italic;
		padding: 0;
	}

	small {
		line-height: inherit;
		font-size: 75%;
	}

	strong {
		font-weight: 700;
		line-height: inherit;
	}

	// Links
	a {
		color: $cgp_link;
		text-decoration: underline;

		&:hover {text-decoration: none;}

	}

}

// ##################################################
// ##################################################


// Headline
.headline {
	position: relative;

	// ##################################################

	// Margin for headlines
	&:not(.headline--nomargin) {

		// Desktop LG and up
		@include bp-up(lg){margin: 0 0 58px;}

		// Tablet P
		@include bp-only(md){margin: 0 0 40px;}

		// Mobile
		@include bp-down(sm){margin: 0 0 37px;}
	}

	// ##################################################

	// For section which have backgrounds
	.light-bg &{

		// Desktop LG and up
		@include bp-up(lg){margin-top: 76px;}

		// Tablet P and down
		@include bp-down(md){margin-top: 51px;}
	}

	// ##################################################

}



//*******************************************************************************
// 2.0 - HEADLINE TITLE
//*******************************************************************************

// Headline title
.headline-title {
	font-family: $font_primary;
	position: relative;
	font-weight: 900;

	// ##################################################

	// Large title
	&--large {
		@extend %heading_h1;
	}

	// ##################################################

	// Medium title
	&--medium {
		@extend %heading_h2;
	}

	// ##################################################

	// Small title
	&--small {
		@extend %heading_h3;
	}

	// ##################################################

	& > * { @extend %inherit_textstyles; }

	// ##################################################


}


//*******************************************************************************
// 3.0 - HEADLINE TEXT
//*******************************************************************************

// Headline text
.headline-text {
	//color: $cgp_black;
	//@extend %page_text;
	background-color: rgba(75, 157, 95, 0.15);

	// ##################################################

	&--large{}

	// ##################################################

	&--small {}

	// ##################################################

	* { @extend %inherit_textstyles; }

	// ##################################################

}



//*******************************************************************************
// 4.0 - HEADLINE ICON
//*******************************************************************************

// Headline Icon
.headline-icon {

	display: flex;
	align-items: center;
	justify-content: center;

	// Mobile
	@include bp-down(sm){}

	// ##################################################

	img {
		display: inline-flex;

		// Mobile
		@include bp-down(sm){}
	}
}

//*******************************************************************************
// 1.0 - DEMO 2 PAGE
//*******************************************************************************

.block-title {
	width: 100%;
}

// ##################################################

.text {
	&--white {
		color: $cgp_white !important;
	}

	// ##################################################

	&--light {
		font-weight: 300 !important;
	}
}

// ##################################################

.block-title {
	width: 100%;

	// Tablet L
	@include bp-only(md) {
		font-size: 60px;
	}

	// Tablet P and down
	@include bp-down(sm) {
		font-size: 40px;
	}
}

// ##################################################

.block-sub-title {
	text-transform: uppercase;
	letter-spacing: 2.99px;
	color: $cgp_orange;
	font-size: 60px;
	font-weight: 900;
	margin-top: 15px;

	// Tablet L
	@include bp-only(md) {
		font-size: 42px;
	}

	// Tablet P and down
	@include bp-down(sm) {
		font-size: 30px;
	}
}

// ##################################################

.popup-pin.popup-pin--demo-point {
	position: absolute;
	padding: 0 10px;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	// White gradient
	&::before {
		@include scale(1);
	}

	// ##################################################

	.home-icon-pin {
		width: 67px;
		height: 67px;
		margin-bottom: 0;

		// Tablet L and down
		@include bp-down(md) {
			width: 50px;
			height: 50px;
		}
	}

	// ##################################################

	.dark-pin {
		border-width: 6px;

		// Tablet L and down
		@include bp-down(md) {
			border-width: 4px;
		}
	}

	// ##################################################

	.home-icon-text {
		position: absolute;
		text-transform: uppercase;
		line-height: 1.05em;

		// Tablet L and down
		@include bp-down(md) {
			font-size: 14px;
			letter-spacing: 1px;
		}
	}

	// ##################################################

	// Top aligned text
	&.text--top {
		text-align: center;

		.home-icon-text {
			top: 0;
			bottom: inherit;
			@include translate(-50%, -100%);
		}
	}

	// ##################################################

	// Left aligned text
	&.text--left {
		text-align: right;

		.home-icon-text {
			top: 50%;
			left: 0;
			bottom: inherit;
			@include translate(-100%, -50%);
		}
	}

	// ##################################################

	// Left top aligned text
	&.text--left-top {
		text-align: right;

		.home-icon-text {
			top: 20%;
			left: 0;
			bottom: inherit;
			@include translate(-100%, 0);
		}
	}

	// ##################################################

	// Left top aligned text
	&.text--left-bottom {
		text-align: right;

		.home-icon-text {
			top: 75%;
			left: 0;
			bottom: inherit;
			@include translate(-100%, -50%);
		}
	}

	// ##################################################

	&.popup-pin--advanced-metering {
		left: 54vw;
		// top: 8.5vw;
		top: 16.5vh;

		// Normal Desktop
		@media (min-width: 1400px) and (max-width: 1700px) {
			left: 62vw;
		}

		// Smaller Desktop
		@media (min-width: 1140px) and (max-width: 1399px) {
			left: 83vw;
		}

		// Tablet L
		@include bp-only(md) {
			left: 74vw;
			top: 14.5vh;
		}

		// Tablet P and down
		@include bp-down(sm) {
			left: 85vw;
			top: 16.5vh;
		}
	}

	// ##################################################

	&.popup-pin--billing {
		left: 47.3vw;
		// top: 10.2vw;
		top: 19.2vh;

		// Normal Desktop
		@media (min-width: 1400px) and (max-width: 1700px) {
			left: 54.3vw;
		}

		// Smaller Desktop
		@media (min-width: 1140px) and (max-width: 1399px) {
			left: 70vw;
		}

		// Tablet L
		@include bp-only(md) {
			left: 65vw;
			top: 18vh;
		}

		// Tablet P and down
		@include bp-down(sm) {
			left: 68vw;
			top: 19vh;
		}
	}

	// ##################################################

	&.popup-pin--customer-portal {
		left: 42.2vw;
		// top: 14.2vw;
		top: 27.2vh;

		// Normal Desktop
		@media (min-width: 1400px) and (max-width: 1700px) {
			left: 48vw;
		}

		// Smaller Desktop
		@media (min-width: 1140px) and (max-width: 1399px) {
			left: 59vw;
		}

		// Tablet L
		@include bp-only(md) {
			left: 57vw;
			top: 26vh;
		}

		// Tablet P and down
		@include bp-down(sm) {
			left: 55vw;
		}
	}

	// ##################################################

	&.popup-pin--customer-app {
		left: 38.6vw;
		// top: 20vw;
		top: 38vh;

		// Normal Desktop
		@media (min-width: 1400px) and (max-width: 1700px) {
			left: 43.2vw;
		}

		// Smaller Desktop
		@media (min-width: 1140px) and (max-width: 1399px) {
			left: 53.6vw;
		}

		// Tablet L
		@include bp-only(md) {
			left: 52vw;
			top: 36vh;
		}

		// Tablet P and down
		@include bp-down(sm) {
			left: 46vw;
			top: 38vh;
		}
	}

	// ##################################################

	&.popup-pin--analytics {
		left: 38vw;
		// top: 26.8vw;
		top: 50.8vh;

		// Normal Desktop
		@media (min-width: 1400px) and (max-width: 1700px) {
			left: 42vw;
		}

		// Smaller Desktop
		@media (min-width: 1140px) and (max-width: 1399px) {
			left: 52vw;
		}

		// Tablet L
		@include bp-only(md) {
			left: 51vw;
			top: 49vh;
		}

		// Tablet P and down
		@include bp-down(sm) {
			left: 44vw;
		}
	}

	// ##################################################

	&.popup-pin--asset {
		left: 40vw;
		// top: 33vw;
		top: 63vh;

		// Normal Desktop
		@media (min-width: 1400px) and (max-width: 1700px) {
			left: 45vw;
		}

		// Smaller Desktop
		@media (min-width: 1140px) and (max-width: 1399px) {
			left: 56vw;
		}

		// Tablet L
		@include bp-only(md) {
			left: 54vw;
			top: 61vh;
		}

		// Tablet P and down
		@include bp-down(sm) {
			left: 49vw;
		}
	}

	// ##################################################

	&.popup-pin--workforce {
		left: 44.4vw;
		// top: 38.2vw;
		top: 73.2vh;

		// Normal Desktop
		@media (min-width: 1400px) and (max-width: 1700px) {
			left: 51vw;
		}

		// Smaller Desktop
		@media (min-width: 1140px) and (max-width: 1399px) {
			left: 64.4vw;
		}

		// Tablet L
		@include bp-only(md) {
			left: 60.4vw;
			top: 71vh;
		}

		// Tablet P and down
		@include bp-down(sm) {
			left: 61vw;
		}
	}

	// ##################################################

	&.popup-pin--active-leakage {
		left: 51vw;
		// top: 41vw;
		top: 79vh;

		// Normal Desktop
		@media (min-width: 1400px) and (max-width: 1700px) {
			left: 59vw;
		}

		// Smaller Desktop
		@media (min-width: 1140px) and (max-width: 1399px) {
			left: 76vw;
		}

		// Tablet L
		@include bp-only(md) {
			left: 70vw;
			top: 77vh;
		}

		// Tablet P and down
		@include bp-down(sm) {
			left: 77vw;
			top: 78vh;
		}
	}
}

// ##################################################

.popup.popup--responsive {

	// Tablet L and down
	@include bp-down(md) {
		position: fixed;
		left: 0 !important;
		top: 0 !important;
		bottom: 0 !important;
		right: 0 !important;
		width: 100%;
	}

	.popup-main {

		// Tablet L and down
		@include bp-down(md) {
			padding: 170px 100px 40px 60px;
		}
	}
}

// ##################################################

.page--demo-point {

	// ##################################################

	.column--narrow {
		justify-content: flex-start;
		padding-top: 192px;

		// Tablet P and down
		@include bp-down(sm) {
			padding-top: 140px;
			padding-left: 50px;
		}
	}

	// ##################################################

	.wrapper-background {

		// Normal Desktop
		@media (min-width: 1400px) and (max-width: 1700px) {
			background-position: 40% center;
		}

		// Normal Desktop
		@media (min-width: 1140px) and (max-width: 1399px) {
			background-position: 33% center;
		}

		// Tablet L
		@include bp-only(md) {
			background-position: 30% center;
		}

		// Tablet P and down
		@include bp-down(sm) {
			background-position: 40% center;
		}
	}

	// ##################################################

	.popup-close {

		// Tablet L and down
		@include bp-down(md) {
			position: fixed;
			left: inherit !important;
			right: 15px;
			top: 10px !important;
		}
	}
}

//*******************************************************************************
// 1.0 - WEBFONTS : Google fonts
//*******************************************************************************

//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');



//*******************************************************************************
// 2.0 - WEBFONTS : Custom imports
//*******************************************************************************

// Regular
@font-face {
	font-family: "Gilroy";
	font-weight: 300;
	font-style: normal;
	src: url('../font/light/gilroy-light.eot?#iefix') format('embedded-opentype'),
	     url('../font/light/gilroy-light.otf')  format('opentype'),
		 url('../font/light/gilroy-light.woff') format('woff'),
		 url('../font/light/gilroy-light.ttf')  format('truetype'),
		 url('../font/light/gilroy-light.svg#gilroy-light') format('svg');
}

// ##################################################
// ##################################################

// Regular
@font-face {
	font-family: "Gilroy";
	font-weight: 400;
	font-style: normal;
	src: url('../font/regular/gilroy-regular.eot?#iefix') format('embedded-opentype'),
	     url('../font/regular/gilroy-regular.otf')  format('opentype'),
		 url('../font/regular/gilroy-regular.woff') format('woff'),
		 url('../font/regular/gilroy-regular.ttf')  format('truetype'),
		 url('../font/regular/gilroy-regular.svg#gilroy-regular') format('svg');
}

// ##################################################
// ##################################################

// Bold
@font-face {
	font-family: "Gilroy";
	font-weight: 700;
	font-style: normal;
	src: url('../font/bold/gilroy-bold.eot?#iefix') format('embedded-opentype'),
	     url('../font/bold/gilroy-bold.otf')  format('opentype'),
		 url('../font/bold/gilroy-bold.woff') format('woff'),
		 url('../font/bold/gilroy-bold.ttf')  format('truetype'),
		 url('../font/bold/gilroy-bold.svg#gilroy-bold') format('svg');
}

// ##################################################
// ##################################################

// ExtraBold
@font-face {
	font-family: "Gilroy";
	font-weight: 900;
	font-style: normal;
	src: url('../font/extrabold/gilroy-extrabold.eot?#iefix') format('embedded-opentype'),
		 url('../font/extrabold/gilroy-extrabold.woff') format('woff'),
		 url('../font/extrabold/gilroy-extrabold.ttf')  format('truetype'),
		 url('../font/extrabold/gilroy-extrabold.svg#gilroy-bold') format('svg');
}

// ##################################################
// ##################################################

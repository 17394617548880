//*******************************************************************************
// 0.0 - GENERAL
//*******************************************************************************

.main {
	background: #2e2d32 url(../img/bg/bg.png) no-repeat scroll center / cover;
}





//*******************************************************************************
// 1.0 - WRAPPERS
//*******************************************************************************

.wrapper {
	display: flex;
	align-items: center;
	position: absolute;
	left: 0;
	top: 0;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	overflow: hidden;

	.block-title {}

	.block-text { margin-top: 50px; }

	// ##################################################

	.column {
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		height: 100%;
		padding-left: 80px;
		padding-right: 80px;
		padding-top: 100px;
		padding-bottom: 100px;

		// ##################################################

		&--narrow { width: 35%; }

		// ##################################################

		&--wide { width: 65%; }

		// ##################################################

		&--full { width: 100%; }

		// ##################################################

	}

	// ##################################################

	// Background (image or video)
	&-background {
		background: #2e2d32 url(../img/bg/bg.png) no-repeat scroll center / cover;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		pointer-events: none;
		z-index: -1;

		video {
			min-height: 100%;
			min-width: 100%;
		}

	}

	// ##################################################

	// Homepage wrapper
	&--homepage {

		@include translate(0, 0);
		@include transition(transform 1s $transition_easing_fadeup);

		// Main page
		.page--main & { @include translate(-100%,0); }

		// Subpage
		.page--homepage.show-subpage & { @include translate(0, -100%); }

		// ##################################################

		.title {
			opacity: 1;
			@include transition(opacity 0.5s $transition_easing_fadeup 1s);

			.screensaver-active & {
				opacity: 0;
				@include transition(opacity 0.5s $transition_easing_fadeup);
			}

		}

	}

	// ##################################################

	// Main wrapper
	&--main {
		@include translate(0, 0);
		@include transition(transform 1s $transition_easing_fadeup);

		// Subpage
		.page--main.show-subpage & { @include translate(0, -100%); }

		// Homepage
		.page--homepage & { @include translate(100%, 0); }

	}

	// ##################################################

	// Subpage wrapper
	&--subpage {
		@include translate(0, 100%);
		@include transition(transform 1s $transition_easing_fadeup);

		// Subpage
		.show-subpage & { @include translate(0, 0); }

		// ##################################################

		// Gradient for the bottom menu
		&:before {
			background-image: linear-gradient(to top, rgba(46, 46, 49, 0) 0%, #28282a 100%);
			width: 100%;
			height: 280px;
			opacity: 0;
			pointer-events: none;
			@extend %content;

			@include transition(opacity 250ms $transition_easing_fadeup);

			// ##################################################

			// Header on subpage
			.show-subpage & {
				opacity: 1;
				@include transition(opacity 250ms $transition_easing_fadeup 500ms);
			}

		}

		// ##################################################

		.column {

			&--narrow {
				width: 42%;
				position: relative;
				z-index: 1;

				&:before {
					background: linear-gradient(90deg, rgba(233,102,80,1) 0%, rgba(240,154,139,0.85) 100%);
					z-index: -1;
					pointer-events: none;
					@extend %content;
				}

				&:after {
					background: url(../img/image_dots.svg) no-repeat scroll left bottom / 100% auto;
					z-index: -1;
					pointer-events: none;
					@extend %content;
				}

			}

			&--wide {
				width: 58%;
				z-index: 2;
			}

		}

		// ##################################################

		.title {

			&, & span { color: #434346; }

		}

	}

	// ##################################################

}








//*******************************************************************************
// 2.0 - FORM
//*******************************************************************************

@include placeholder($cgp_orange);

.enews-form {
	font-size: 0;

	// ##################################################

	.form-cell {

		// Mobile
		@include bp-only(xs) {
			display: block;
		}

	}

	.form-group {
		position: relative;

		// Tablet and up
		@include bp-up(sm) {margin-bottom: 30px;}

		// Mobile
		@include bp-only(xs) {margin-bottom: 24px;}

	}

	// ##################################################

	// Validation failed text
	.validation-failed-text {
		color: $cgp_validation_failed;
		font-family: $font_primary;
		font-size: 12px;
		font-weight: 400;

		position: absolute;
		left: 0;
		bottom: 0;
		padding-top: 4px;
		white-space: nowrap;
		display: none;

		// Tablet and up
		@include bp-up(sm) {
			transform: translate(0, 100%);
		}

		// Mobile
		@include bp-only(xs) {
			position: relative;
		}

	}

	// Validation invalid
	.validation-failed {

		input,
		.form-label {color: $cgp_validation_failed !important;}

		.validation-failed-text {display: block;}

		input[type="radio"] {
			& ~ label:before {border-color: $cgp_validation_failed;}
		}

	}

	// ##################################################

	// Label - placeholder
	.form-label {
		color: $cgp_black;
		cursor: text;
		font-size: 14px;
		font-weight: 600;
		height: 14px;
		line-height: 14px;

		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;

		transform: translate(0, -50%);
		transition: all 150ms ease-in;
	}

	// Input focus or valid

	//input:valid ~ .form-label,
	//input[required]:valid ~ .form-label,
	input.is-active ~ .form-label,
	input:focus ~ .form-label {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		top: 0;

		transform: translate(0, -100%);
	}

	// ##################################################

	input[type="text"],
	input[type="email"] {
		color: $cgp_black;
		//font-family: $font_secondary;
		background-color: transparent !important;
		border: none;
		box-shadow: none !important; // FF default failed validation reset
		border-bottom: 2px solid currentColor;

		//font-weight: 500;
		font-weight: 600;
		height: 40px;
		font-size: 16px;
		line-height: 40px;

		//min-width: 236px;
		min-width: 230px;
		width: 100%;

		transition: all 500ms ease-in;

	}

	// Chrome autofill reset
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus {
	    //background-color: transparent !important;
	    //-webkit-text-fill-color: $cgp_black !important;
	    //-webkit-box-shadow: 0 0 0px 0px transparent !important;
	    transition: background-color 1s ease-in-out 5000s;
	}

	// ##################################################

	.form-check-inline {
		margin-right: 24px;

		input {display: none;}

		// Checked input
		input:checked ~ .form-check-label:after {opacity: 1;}

	}

	// Radio labels
	.form-check-label {
		margin-bottom: 0;
		display: block;
		padding-left: 30px;
		position: relative;

		&:before, &:after {
			@extend %content;
		}

		// Radio border
		&:before {
			border: 2px solid $cgp_black;
			border-radius: 100%;
			margin-top: 2px;
			height: 18px;
			width: 18px;
		}

		// Radio selected style
		&:after {
			background-color: $cgp_black;
			border-radius: 100%;
			margin-left: 4px;
			margin-top: 6px;
			width: 10px;
			height: 10px;
			opacity: 0;
		}

	}

	// ##################################################

	// Submit
	.form-submit {
		margin-bottom: 0;

		// Tablet and up
		@include bp-up(sm) {
			padding-top: 30px;
			display: flex;
			justify-content: flex-end;
		}

		// Mobile
		@include bp-only(xs) {
			padding-top: 20px;
			margin-bottom: 120px;
		}

	}

	// ##################################################

	button {}

	// ##################################################

}




//*******************************************************************************
// 2.1 - FORMS - TERMS CHECKBOX
//*******************************************************************************

// Terms
.terms {

	@extend .d-flex;
	@extend .align-items-start;
	justify-content: stretch;

	// Mobile
	@include bp-only(xs) {
		flex-wrap: wrap;
	}

}


// Terms text
.terms-text {
	@extend %heading_h6 !optional;

	color: $cgp_black;
	font-weight: 400;

	// Tablet and up
	@include bp-up(sm) {
		margin-right: 25px;
		width: 108px;
		min-width: 108px;
	}

	// Mobile
	@include bp-only(xs) {
		margin-bottom: 10px;
	}

}


// CHECKBOX for terms
.form-checkbox	{
	position: relative;

	// Tablet and up
	@include bp-up(sm) {width: 100%;}

	// ##################################################

	// Input
	input {display: none;}

	// ##################################################

	// Label
	&-label {}

	// ##################################################

	// Text
	span {
		@extend %heading_h6 !optional;

		//float: left;
		color: $cgp_black;
		display: block;
		font-weight: 400;
		cursor: pointer;
		position: relative;
		width: 100%;

		// Tablet and up
		@include bp-up(sm) {padding-left: 30px;}

		// Mobile
		@include bp-only(xs) {padding-left: 36px;}

		// ##################################################

		a {
			@extend %inherit_textstyles;

			text-decoration: underline;
			font-weight: 700;
		}

		// ##################################################

		&:before, &:after {
			@extend %content;

			// Tablet and up
			@include bp-up(sm) {
				height: 18px;
				width: 18px;
			}

			// Mobile
			@include bp-only(xs) {
				height: 25px;
				width: 25px;
			}

		}

		// Frame
		&:before {
			border: 2px solid $cgp_black;

			@include transition(all 150ms ease-in);
		}

		// Square
		&:after	{
			background-color: $cgp_black;

			transform: scale(0);
			transition: transform 0.2s ease-in-out;
		}
	}

	// ##################################################

	// Checkbox checked
	input:checked + span {
		&:after	{ transform: scale(0.45); }
	}

	// Validation failed
	&.validation-failed {

		span:before {border-color: #f00;}

	}

}

// ##################################################
// Gutter gaps
// ##################################################

//$gutter: $grid-gutter-width;
$gutter: $grid-gutter-width;



// ##################################################
// Main Fonts
// ##################################################

$font_primary:   'Gilroy', sans-serif;
$font_secondary: $font_primary;



// ##################################################
// Colors
// ##################################################

// Moved to config file
$cgp_white:             map-get($colors, cgp_white);
$cgp_black:             map-get($colors, cgp_black);
$cgp_orange:            map-get($colors, cgp_orange);
$cgp_pink:              map-get($colors, cgp_pink);

$cgp_validation_failed: map-get($colors, cgp_validation_failed);
$cgp_link:              map-get($colors, cgp_link);
$cgp_red:				map-get($colors, cgp_red);
$cgp_gray:				map-get($colors, cgp_gray);

//$cgp_header_items: #fcd7d2;
$cgp_header_items: #fff;


// ##################################################
// Paths
// ##################################################

// Img path
$img_path:              '../img/';



// ##################################################
// Z-index
// ##################################################

$zindex_header:         9;
$zindex_header_top:     10;
$zindex_menu:           8;

$zindex_site_loader:	99;
$zindex_cookie_notice:  98;
$zindex_modal:          99;
$zindex_slider_arrows:  89;



// ##################################################
// Margins
// ##################################################

// Breakpoint margins
$el_margin_bottom_xl:   40px;
$el_margin_bottom_lg:   32px;
$el_margin_bottom_md:   24px;
$el_margin_bottom_sm:   24px;
$el_margin_bottom_xs:   16px;

// Section maargins
$section_margin_xl:     160px;
$section_margin_lg:     120px;
$section_margin_md:     80px;
$section_margin_sm:     60px;
$section_margin_xs:     80px;



// ##################################################
// Class names (read from config JSON)
// ##################################################

// Usage: body.#{$class_open_menu} {};

$class_open_menu:               map-get($class_names, class_open_menu);
$class_open_search:             map-get($class_names, class_open_search);
$class_open_submenu:            map-get($class_names, class_open_search);
$class_visible:                 map-get($class_names, class_visible);
$class_hidden:                  map-get($class_names, class_hidden);

$class_media_playing:           map-get($class_names, class_media_playing);
$class_active:                  map-get($class_names, class_active);
$class_selected:                map-get($class_names, class_selected);
$class_open:                    map-get($class_names, class_open);
$class_mobile:                  map-get($class_names, class_mobile);
$class_modal_open:              map-get($class_names, class_modal_open);
$class_validation_failed:       map-get($class_names, class_validation_failed);



// ##################################################
// Transitions & easings
// ##################################################

// Easings
$easing1: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easingType1: cubic-bezier(0.46, 0.03, 0.52, 0.96);
$easingType2: cubic-bezier(0.19, 1, 0.22, 1);


// Transitions
$transition_duration: 250ms;
$transition_easing:   linear;

$transition_easing_fadeup:         cubic-bezier(0.46, 0.03, 0.52, 0.96);
$transition_easing_fadeLeftRight:  cubic-bezier(0.19, 1, 0.22, 1);

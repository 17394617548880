@charset "UTF-8";
@import '../../config.json'; // Load config file

//*******************************************************************************
// X.0 - GENERAL LAYOUT
//*******************************************************************************

// GLOBAL VARS: breakpoints;
$bkp_hd:                   1920px;             // Desktop HD
$bkp_xl:                   1440px;             // Desktop Large
$bkp_lg:                   1024px;             // Tablet landscape
$bkp_md:                   768px;              // Tablet portrait
$bkp_sm:                   375px;              // Mobile : 375px
$bkp_xs:                   320px;              // Mobile : 320px

// GRID GUTTERS
$gutter_xl:                0px;                // Desktop HD
$gutter_lg:                0px;                // Desktop - large
$gutter_md:                0px;                // Desktop - regular / Tablet landscape
$gutter_sm:                0px;                // Tablet portrait
$gutter_xs:                0px;                // Mobile

$grid-gutter-width:    $gutter_xl !default;    // Bootstrap default gutter override

// CONTAINER WIDTHS
 $container_xl: 	       1552px;              // Desktop HD
 $container_lg: 	       1140px;              // Desktop - large / Tablet landscape
 $container_md:    	       960px;               // Desktop - regular
 $container_sm:     	   704px;               // Tablet - portrait
 $container_xs:     	   calc(100% - 48px);   // Mobile

// Bootstrap breakpoints override
// $grid-breakpoints: (
// 	xs: 0,                                      // default: 0
// 	sm: $bkp_md,                                // default: 576px
// 	md: $bkp_lg,                                // default: 768px
// 	lg: $bkp_xl,                                // default: 992px
// 	xl: $bkp_hd                                 // default: 1200px
// ) !default;

// // Bootstrap container override
// $container-max-widths: (
// 	xs: 320px,                               // default: 320px
// 	sm: 540px,                               // default: 540px
// 	md: 720px,                               // default: 720px
// 	lg: 960px,                               // default: 960px
// 	xl: 1140px                               // default: 1140px
// ) !default;

// Bootstrap overrides
$grid-columns:             12 !default;      // default: 12
$grid-gutter-width:        32px !default;    // default: 30px
$paragraph-margin-bottom:  0 !default;       // default: 1rem



//*******************************************************************************
// X.1 - INCLUDES: Bower
//*******************************************************************************

//@import "../../node_modules/bootstrap/scss/bootstrap-grid";

// Boostrap 4 components

// Core
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Rest
//@import "../../node_modules/bootstrap/scss/alert";
//@import "../../node_modules/bootstrap/scss/badge";
//@import "../../node_modules/bootstrap/scss/breadcrumb";
////@import "../../node_modules/bootstrap/scss/button-group";
//@import "../../node_modules/bootstrap/scss/buttons";
//@import "../../node_modules/bootstrap/scss/card";
//@import "../../node_modules/bootstrap/scss/carousel";
//@import "../../node_modules/bootstrap/scss/close";
//@import "../../node_modules/bootstrap/scss/code";
//@import "../../node_modules/bootstrap/scss/custom-forms";
//@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/grid";
//@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/input-group";
//@import "../../node_modules/bootstrap/scss/jumbotron";
//@import "../../node_modules/bootstrap/scss/list-group";
//@import "../../node_modules/bootstrap/scss/media";
//@import "../../node_modules/bootstrap/scss/modal";
//@import "../../node_modules/bootstrap/scss/nav";
//@import "../../node_modules/bootstrap/scss/navbar";
//@import "../../node_modules/bootstrap/scss/pagination";
//@import "../../node_modules/bootstrap/scss/popover";
//@import "../../node_modules/bootstrap/scss/print";
//@import "../../node_modules/bootstrap/scss/progress";
//@import "../../node_modules/bootstrap/scss/reboot";
////@import "../../node_modules/bootstrap/scss/root";
////@import "../../node_modules/bootstrap/scss/tables";
//@import "../../node_modules/bootstrap/scss/tooltip";
////@import "../../node_modules/bootstrap/scss/transitions";
//@import "../../node_modules/bootstrap/scss/type";

// Utilities
//@import "../../node_modules/bootstrap/scss/utilities";
////@import "../../node_modules/bootstrap/scss/utilities/align";
//@import "../../node_modules/bootstrap/scss/utilities/background";
//@import "../../node_modules/bootstrap/scss/utilities/borders";
@import "../../node_modules/bootstrap/scss/utilities/clearfix";
@import "../../node_modules/bootstrap/scss/utilities/display";
@import "../../node_modules/bootstrap/scss/utilities/embed";
@import "../../node_modules/bootstrap/scss/utilities/flex";
////@import "../../node_modules/bootstrap/scss/utilities/float";
////@import "../../node_modules/bootstrap/scss/utilities/position";
////@import "../../node_modules/bootstrap/scss/utilities/screenreaders";
//@import "../../node_modules/bootstrap/scss/utilities/shadows";
//@import "../../node_modules/bootstrap/scss/utilities/sizing";
//@import "../../node_modules/bootstrap/scss/utilities/spacing";
@import "../../node_modules/bootstrap/scss/utilities/text";
////@import "../../node_modules/bootstrap/scss/utilities/visibility";


//*******************************************************************************
// X.2 - INCLUDES: Website
//*******************************************************************************

@import "base/reset";                       // Reset - DO NOT DELETE ANY ENTRIES
@import "mixins/mixins";                    // Mixins - DO NOT DELETE ANY ENTRIES
//@import "base/custom_grid";                 // Custom bootstrap grid

@import "include/fauxgrid";                 // For display purposes only

// Variables and general styles
@import "base/webfonts";                    // Webfonts
@import "base/variables";                   // Variables
@import "base/icons";                       // Base64 encoded images
@import "base/extensions";                  // Extensions for typography, margins, etc...
@import "include/general";                  // General styles and custom resets

// Building blocks
@import "include/atoms";                    // Small build blocks
@import "include/molecules";                // Larger elements
@import "include/forms";                    // Forms
//@import "include/news";                    // News/articles
//@import "include/news_detail";             // News/articles detail

// Page components
@import "include/header";                   // Header
@import "include/menu";                     // Navigations
//@import "include/footer";                   // Footer

// Modular styles
//@import "include/sliders";                  // Sliders (slick carousels)
//@import "include/content_cards";            // Content cards
@import "include/headlines";                // Headlines and titles
//@import "include/gallery";

// Pages
@import "include/pages";                    // General pages
@import "include/home";                     // Homepage
@import "include/demo_2";                   // Demo 2 page
//@import "include/modal";                    // Modal for video

// GFX
//@import "include/animations";               // Effects and animation


@import "include/popup";               // Effects and animation



//body {
	//background-color: $success2;
	//background-color: map-get($colors, success);
	//background-color: #432eee;
//}

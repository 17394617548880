//*******************************************************************************
// 1.0 - SECTIONS
//*******************************************************************************

.sc-general {
	margin-bottom: $section_margin_sm;
	margin-top: $section_margin_sm;
	position: relative;
	z-index: 5;
	width: 100%;
}


// ##################################################
// ##################################################


// General text style
.text {
	//font-family:$font_primary;

	// ##################################################

	p {
		cursor: text;
	}

	// ##################################################

	// Button after text
	& + .btn {}

	// ##################################################

	// General link style
	a {
		color: $cgp_black;
		border-bottom: 1px solid transparent;

		// ##################################################

		//&:hover, &:active, &:focus {
		//	border-bottom: 1px solid currentColor;
		//}
	}

}


// ##################################################
// ##################################################


// Page text
.page-text {
	@extend %page_text !optional;

	// ##################################################

	// Paragraphs
	p:not(:last-of-type) {
		margin-bottom: 1em;
	}

	// ##################################################

}





//*******************************************************************************
// X.X - BACKGROUND IMAGE
//*******************************************************************************
.sc-fh {
	height: 100vh;
}

.js-responsive-bg-image {
	background: transparent no-repeat scroll center / cover;
}

// Lazy loaded image element
.js-responsive-bg-image > picture,
.js-responsive-bg-image > picture img,
.js-responsive-bg-image > img {
    position: absolute;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    pointer-events: none;
}



//*******************************************************************************
// 9.0 - Cookies
//*******************************************************************************
#div-cookie {
	background-color: #f2f2f2;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: $zindex_cookie_notice;

	display: none;

	// ##################################################

	.content {
		padding: 15px 24px;

		// Table and up
		@include bp-up(sm) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

	}

	// ##################################################

	.text {
		margin-bottom: 0;
		font-size: 16px;
		line-height: 1.25em;

		// Table and up
		@include bp-up(sm) {padding-right: 118px;}

		// ##################################################

		a {
			cursor: pointer;
			font-weight: 700;
			border-bottom: 1px solid currentColor;

			&:hover {border-bottom: none;}

		}

	}

	// ##################################################

	span {color: $cgp_black;}

	// ##################################################

	.buttons {

		// Mobile
		@include bp-down(xs) {padding-top: 18px;}

		// ##################################################

		// Cookie button
		.btn-cookie {

			color: $cgp_white;

			&:before {background-color: $cgp_black;}

			// Mobile
			@include bp-only(xs) {max-width: 100%;}

		}

	}

	// ##################################################

}



// ##################################################
// ##################################################

// TEXT COOKIES
.text-cookie {

	&-disabled {
		display: none;
	}

	&-enabled {
		display: none;
	}

}

//*******************************************************************************
// 1.0. HEADER MAIN
//*******************************************************************************

.header-main {
	z-index: 999;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	pointer-events: none;

	// ##################################################
	// White background for mobile

	&.is-white {

		// Mobile
		@include bp-only(xs) {
			background-color: $cgp_white;
			@include transition(background-color 500ms ease-in);
		}
	}

	// ##################################################

	&-top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		background-color: transparent;
		padding: 40px 40px 0 56px;
		position: relative;
		z-index: $zindex_header_top;

		// Mobile
		@include bp-only(xs) {
			// padding-top: 24px;
			// padding-bottom: 8px;
			padding: 10px 15px; // To make proper heade in menu

			.#{$class_open_menu} & {
				background-color: #676769; // To prevent menu text overlapping with logo
				@include transition(background-color 0.6s $easingType2 0.3s);
			}
		}
	}

	// ##################################################

}





//*******************************************************************************
// 2.0. HEADER LOGO
//*******************************************************************************

.header-logo {
	cursor: pointer;
	display: flex;
	align-items: center;
	pointer-events: all;

	.screensaver-active & {pointer-events: none;}

	// ##################################################

	// Main header logo
	svg {
		display: inline-block;
		height: auto;
		width: 222px;

		//Mobile
		@include bp-only(xs) {
			height: auto;
			width: 140px;
		}

		// ##################################################

		.header-logo-text-upper,
		.header-logo-text-lower {
			fill: $cgp_header_items;

			.#{$class_open_menu} & { fill: $cgp_white; }

			.page-white & { fill: $cgp_black; }

		}

		// ##################################################

		.header-logo-image {
			fill: $cgp_header_items;
			@include transition(fill 0.5s ease-in-out);

			// ##################################################

			.#{$class_open_menu} & { fill: $cgp_white; }

			.page-white &,
			.screensaver-active & { fill: $cgp_orange; }

		}

		// ##################################################

	}
}



//*******************************************************************************
// 3.0. HEADER TOOLBAR
//*******************************************************************************

.header-toolbar {
	display: flex;
	opacity: 1;
	visibility: visible;
	pointer-events: all;
	@include transition(visibility 0ms linear, opacity 250ms $transition_easing_fadeup, transform 300ms $transition_easing_fadeup);

	.page--homepage &,
	.page--homepage &-item {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		@include transition(visibility 0ms linear 500ms, opacity 250ms $transition_easing_fadeup, transform 300ms $transition_easing_fadeup);
	}

	// ##################################################

	&-item {
		height: 60px;
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: all;

		// Mobile
		@include bp-only(xs) { width: 30px; }

		// ##################################################

		&:not(:first-of-type) {
			margin-left: 20px;

			// Mobile
			@include bp-only(xs) { margin-left: 10px; }

		}

		// ##################################################

		a {
			display: inline-block;
			vertical-align: middle;
		}

		// ##################################################

		&, & * { cursor: pointer; }

		// ##################################################

		svg {
			width: 100%;
			height: auto;

			path {
				fill: $cgp_header_items;

				// Alternate version, when page turn white
				.page-white & { fill: $cgp_orange; }

			}
		}

		// ##################################################

	}

	// ##################################################

}


// ##################################################
// ##################################################

.back-button {
	@include transition(opacity 0.4s ease);

	.#{$class_open_menu} & {
		pointer-events: none;
		opacity: 0;
	}

}


.menu-open {
	position: relative;
}

//*******************************************************************************
// 0.0 - SCREENSAVER
//*******************************************************************************

.screensaver {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	z-index: 2;

	visibility: hidden;
	pointer-events: none;
	opacity: 0;
	@include transition(visibility 0ms linear 1s, opacity 1s $transition_easing_fadeup);

	// ##################################################

	.screensaver-active & {
		opacity: 1;
		pointer-events: all;
		visibility: visible;
		@include transition(visibility 0ms linear, opacity 1s $transition_easing_fadeup);
	}

	// ##################################################

	.title {
		position: absolute;
		left: 100px;
		top: 37.5%;

		opacity: 0;
		@include transition(opacity 0.5s $transition_easing_fadeup);

		.screensaver-active &.is-active {
			opacity: 1;
			@include transition(opacity 0.5s $transition_easing_fadeup 1s);
		}

	}

	// ##################################################

	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		pointer-events: none;
		@include transition(opacity 0.5s $transition_easing_fadeup);

		&.fade-out { opacity: 0; }

	}

}





//*******************************************************************************
// 1.0 - HOME ICONS
//*******************************************************************************

.home-icons {
	padding-top: 66px;

	@include flexbox();
	@include justify-content(center);

}

.home-icon {
	position: relative;
	padding: 0 30px;

	// ##################################################

	&-pin {
		position: relative;
		width: 152px;
		height: 152px;
		margin-bottom: 38px;
	}

	// ##################################################

	&-text {
		color: $cgp_white;
		font-size: 19px;
		letter-spacing: 2.85px;
		font-weight: 900;
		line-height: 1.25em;

		position: absolute;
		bottom: 0;
		left: 50%;
		white-space: nowrap;

		@include translate(-50%, 100%);

	}

	// ##################################################

}


// ##################################################
// ##################################################


.dark-pin {
	border: 12px solid rgba($cgp_orange, 0.6);
	height: 100%;
	width: 100%;
	position: absolute;
	border-radius: 50%;

	@include scale(1);
	@include transition(transform 250ms ease-in);

	// ##################################################

	&:hover {
		@include scale(1.25);
	}

	// ##################################################

	&,
	& * {
		cursor: pointer;
	}

	// ##################################################

	&-bg {
		background: url("data:image/svg+xml,#{$dark_pin}") no-repeat scroll center / cover;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 2;
	}

	// ##################################################

	&-image {
		position: absolute;
		z-index: 2;
		display: flex;
		padding: 24px;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;

		img,
		svg {
			width: 100%;
			height: 100%;
			max-width: 90%;
			max-height: 90%;
			margin: auto;
		}
	}


	// ##################################################

}





//*******************************************************************************
// X.0 - MISC
//*******************************************************************************

.text-orange {
	color: #e96650 !important;
	font-size: 15px;
	line-height: 1.33;
}


.text-infographic {
	color: #58585a !important;
	font-size: 34px;
	font-weight: 300;
	line-height: 1;
}


// Donut pie chart
.donut-pie {

	// ##################################################

	&-container {
		@include flexbox();
		@include align-items(flex-start);

		&--center {
			@include justify-content(center);
		}

		.text:not(:first-of-type) {
			margin-top: 4px;
		}

	}

	// ##################################################

	&-wrapper {
		position: relative;
		@include inline-flexbox();
		@include align-items(center);
	}

	// ##################################################

	&-text {
		padding-left: 16px;
		position: absolute;
		left: 100%;
	}

	// ##################################################

	// SVG
	&-chart {

		svg {
			min-width: 179px;
			min-height: 179px;
		}

		.donut-pie-circle {
			fill: none;
			stroke: url(#b);
			stroke-dashoffset: 468;
			stroke-dasharray: 468;
			stroke-miterlimit: 10;
			stroke-width: 29;
			@include transition(stroke-dashoffset 1s ease-in-out);
		}

		text {
			fill: #e96650;
			font-size: 50px;
		}

	}

	// ##################################################

}


// Particle effect - DISABLED
.eff {
	overflow: visible;
	position: absolute;
	right: 85px;
	bottom: 45px;
	width: 120px;
	mix-blend-mode: color-dodge;
	display: none;

	circle {

		// &:nth-child(odd) {
		// 	animation-name: svgcircles1;
		// 	animation-duration: 10s;
		// 	animation-iteration-count: infinite;
		// 	transform-origin: center;
		// 	animation-direction: alternate;
		// 	animation-timing-function: $easingType1;
		// }

		// &:nth-child(even) {
		// 	animation-name: svgcircles2;
		// 	animation-duration: 8s;
		// 	animation-iteration-count: infinite;
		// 	transform-origin: center;
		// 	animation-direction: alternate;
		// 	animation-timing-function: $easingType2;
		// }

	}

}

@keyframes svgcircles1 {

	0% {
		@include scale(1);
	}

	50% {
		@include scale(1.2);
	}

	100% {
		@include scale(1);
	}

}

@keyframes svgcircles2 {

	0% {
		@include scale(1);
		@include rotate(5deg);
	}

	50% {
		@include scale(1.2);
		@include rotate(-5deg);
	}

	100% {
		@include scale(1);
		@include rotate(0deg);
	}

}

// Bottom right particles canvas
.bottom-right-particle {
	overflow: visible;
	position: absolute;
	right: 85px;
	bottom: 45px;
	width: 120px;
    height: 120px;
}


// ##################################################
// ##################################################

.submenu-line-effect {
	overflow: visible;
	height: auto;
	min-height: 2px;
	width: 100%;

	polyline {
		fill: none;
		stroke: url(#submenuLineGrad);
		//stroke-miterlimit: 10;

		// will-change: transform;
		// animation-name: svgeffect;
		// animation-duration: 10s;
		// animation-iteration-count: infinite;
		// transform-origin: center;
		// animation-direction: alternate;
		// animation-timing-function: linear;
	}

}

@keyframes svgeffect {

	0% {
		transform: translateZ(0);
	}

	50% {
		transform: translateZ(0.1);
	}

	100% {
		transform: translateZ(0);
	}

}

//*******************************************************************************
// X.X - CLEVER HACKS
//*******************************************************************************

// Contaer for 100% screen width, even if inside an existing bootstrap cell
.full-width-container {
	position: relative;
}

.full-width-content {
	width: 100vw;
	left: 50%;
	position: relative;
	margin-left: -50vw;
}



//*******************************************************************************
// 1.0 - BUTTONS
//*******************************************************************************

/*
@styleguide
@title Button

Use the button classes on and `<a>`, `<button>`, `<input>` elements.

Generic button:
<button class="btn"><span>Sample Button</span></button>

	<button class="btn"><span>Sample Button</span></button>

Send button:
<button class="btn : btn--send"><span>Send button</span></button>

	<button class="btn : btn--send"><span>Send button</span></button>

FB button:
<button class="btn : btn--fb"><span>FB button</span></button>

	<button class="btn : btn--fb"><span>FB button</span></button>

White button:
<button class="btn : btn--white"><span>White button</span></button>

	<button class="btn : btn--white"><span>White button</span></button>

Centered button:
<button class="btn : btn--center"><span>Center button</span></button>

	<button class="btn : btn--center"><span>Center button</span></button>

*/

.btn {
	padding: 0;
	margin: 0;
	border: 0;
	height: auto;
	cursor: pointer;

	border: none;
	background-color: transparent;
	position: relative;
	//overflow: hidden;
	overflow: visible; // Prevents clipping in IE11

	height: 46px;
	padding: 0 18px;
	max-width: 340px;
	width: 100%;

	color: $cgp_black;
	background-color: transparent;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	line-height: 48px;

	@extend .d-flex;
	//@include inline-flexbox();
	//@include align-items(center);

	&:before {
		@extend %content;
		border-radius: 24px;
		background-color: $cgp_white;
		transition: transform .25s $easing1;
	}

	// Tablet
	@include bp-up(sm) {min-width: 340px;}

	// ##################################################

	// Hover, active, focus states
	&:hover, &:active, &:focus {

		// Tablet
		@include bp-up(sm) {

			&:before {
				transform: scale(1.05, 1.2);
				transition: transform .25s $easing1;
			}

		}

	}

	// ##################################################

	// Containes button text and inherits all text style from the button
	span {
		cursor: pointer;
		position: relative;
		z-index: 2;
		@extend %inherit_textstyles;
	}

	// ##################################################

	// SEND : Button with icon
	// &--send {
	// 	position: relative;

	// 	&:after {
	// 		position: absolute;
	// 		right: 22px;
	// 		content: "";
	// 		display: block;
	// 		background: url("data:image/svg+xml,#{$icon_submit}") no-repeat scroll center / 30px 29px;
	// 		width: 30px;
	// 		height: 100%;
	// 	}

	// }

	// ##################################################

	// FB : Button with icon
	// &--fb {
	// 	position: relative;
	// 	max-width: none;
	// 	padding-right: 56px;

	// 	&:after {
	// 		position: absolute;
	// 		right: 28px;
	// 		content: "";
	// 		display: block;
	// 		background: url("data:image/svg+xml,#{$icon_fb}") no-repeat scroll center / 13px 23px;
	// 		width: 13px;
	// 		height: 100%;
	// 	}

	// }

	// ##################################################

	// Button line
	//&--line {}

	// Submit button
	//&--submit {}

	// Big button
	//&--big {}

	// Small button
	//&--small {}

	// Red button
	//&--red {}

	// White button
	&--white {
		color: $cgp_white;

		// Hover, active, focus states
		&:hover, &:active, &:focus {}

	}

	// Center aligned
	&--center {
		//@include justify-content(center);
		@extend .justify-content-center;
	}


	// ##################################################

	// Hover, active, focus states
	// &:hover, &:active, &:focus {

	// 	&:before {}

	// }

}


//*******************************************************************************
// 1.2 - SOCIAL ICONS
//*******************************************************************************

// Social icons
// .social-icons {
// 	font-size: 0;

// 	// ##################################################

// 	.sico {
// 		display: inline-block;
// 		vertical-align: middle;

// 		&--fb {
// 			background: url("data:image/svg+xml,#{$icon_social_fb}") no-repeat scroll center / 100% auto;
// 			width: 13px;
// 			height: 23px;
// 		}

// 		&--yt {
// 			background: url("data:image/svg+xml,#{$icon_social_yt}") no-repeat scroll center / 100% auto;
// 			width: 29px;
// 			height: 20px;
// 		}

// 		&:not(:last-of-type) {}

// 	}

// }





//*******************************************************************************
// 2.0 - PLAY ICON (for modal video)
//*******************************************************************************

.icon-play {
	background-color: $cgp_white;
	cursor: pointer;
	display: inline-block;
	position: relative;

	border-radius: 100%;
	transition: all 250ms ease-in;

	// Tablet and up
	@include bp-up(sm) {
		width: 176px;
		height: 176px;
		max-height: 176px;
		max-width: 176px;
	}

	// Mobile
	@include bp-only(xs) {
		width: 88px;
		height: 88px;
		max-height: 88px;
		max-width: 88px;
	}

	// ##################################################

	&:before {
		@extend %content;
		background-color: $cgp_black;
		opacity: 0;
		visibility: hidden;

		transform: scale(1.25);
		border-radius: 100%;
		transition: opacity 500ms linear;

	}

	// ##################################################

	// Play triangle
	&:after {
		@extend %content;

		width: 0;
		height: 0;
		border-style: solid;
		border-color: transparent transparent transparent $cgp_black;

		left: 50%;
		top: 50%;

		// Tablet and up
		@include bp-up(sm) {
			border-width: 18px 0 18px 23px;
			margin-top: -18px;
			margin-left: -9px;
		}

		// Mobile
		@include bp-only(xs) {
			border-width: 10px 0 10px 16px;
			margin-top: -10px;
			margin-left: -5px;
		}

	}

	// ##################################################

	// Hover
	&:hover {
		background-color: $cgp_black;

		transform: scale(1.25);

	}

	// ##################################################

}





//*******************************************************************************
// 3.0 - SCROLL DOWN ARROW
//*******************************************************************************

.scroll-arrow {
	position: fixed;
	bottom: 35px;
	left: 50%;
	margin-left: -19px;
	width: 38px;
	text-align: center;
	font-size: 0;
	z-index: 9;


	// When it's ready to go back up, we give it pointer cursor
	&.is-down {cursor: pointer;}

	// Hide it when modal is shown
	.#{$class_modal_open} & {display: none;}

	// Mobile
	@include bp-only(xs) {display: none;}

	// SVG
	svg {
		display: inline-block;
		vertical-align: bottom;
	}

}

/* Reset */

*,*::before,*::after {box-sizing: border-box;}

* {
	margin: 0;
	padding: 0;
	outline: none;
	outline-width: 0;
	text-decoration: none;
}

html {
	height: 100%;
	background-color: transparent;
	border: none;
	font-family: sans-serif;
	font-size: 125%;
	line-height: 1.15;
	color: #000;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0,0,0, 0);
}

body {
	height: 100%;
	font-size: .625em;
	font-size: 50%;
	margin: 0;
	text-align: left;
}


article,aside,details,figcaption,figure,footer,header,hgroup,menu,main,nav,section {display: block;}
code,input,kbd,pre,samp,select,textarea,var {white-space: normal;}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td {vertical-align: top;}

blockquote, q {quotes: none;}

blockquote {margin: 0 0 1rem;}

blockquote:before,blockquote:after,q:before,q:after {
	content: "&#8220";
	content: "\201C";
}

ol,ul,dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dd {margin: 0 0 1rem;}

ol ol,ul ul,ol ul,ul ol {margin-bottom: 0;}

del,s {text-decoration: line-through;}

nav ul {list-style: none;}

abbr,ins {text-decoration: underline;}

address,cite,dfn,em,i,var {font-style: italic;}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

h1,h2,h3,h4,h5,h6,p {margin: 0;}

b,h1,h2,h3,h4,h5,h6,strong,th {
	font-weight: bold;
	line-height: 1.4;
}

b, strong {font-weight: bolder;} // Add the correct font weight in Chrome, Edge, and Safari

/* General */
small {
	font-size: 80%;
	line-height: 1.5;
}

big {
	font-size: 120%;
	line-height: 1.7;
}

code,kbd,samp {
	font-family: inherit;
	letter-spacing: 2px;
	white-space: nowrap;
}

blockquote,dd {padding-left: 2.2em;}

caption,button,input[type="button"],input[type="reset"],input[type="submit"],th {text-align: inherit;}

hr {
	box-sizing: content-box;
	overflow: visible;
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 0;
	background-color: transparent;
	border: none;
	border-top: 1px solid #000;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub { bottom: -.25em; }
sup { top: -.5em; }

pre {
	font-size: 1em;
	font-family: monospace, monospace;
	white-space: pre;
}

pre * {
	font-size: 100%;
	white-space: pre;
}

/* Forms */
optgroup option {
	padding-left: 1.4em;
	font-weight: normal;
}

optgroup {font-weight: bold;}

select {
	height: 1.9em;
	background-color: #fff;
	border: 1px solid #000;
	padding: 0;
}

textarea {
	width: 100%;
	height: 2.4em;
	background-color: #fff;
	border: 1px solid #000;
	font-family: inherit;
}

input,button,select,optgroup,textarea {
  margin: 0; // Remove the margin in Firefox and Safari
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {cursor: pointer;}

button,input {overflow: visible;} // Show the overflow in Edge

button,select {text-transform: none;} // Remove the inheritance of text transform in Firefox

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	padding: 0;
	height: 1.5em;
	line-height: 1.5em;
	background-color: transparent;
	border: 1px solid #000;
	cursor: text;
	vertical-align: top;
	margin: 0;
}

input[type="radio"] {
	margin: 1px 0;
	width: 1.3em;
	height: 1.3em;
	vertical-align: bottom;
	box-sizing: border-box; // Add the correct box sizing in IE 10-
	padding: 0; // Remove the padding in IE 10-
}

input[type="checkbox"] {
	width: 1.3em;
	height: 1.6em;
	vertical-align: top;
}

input[type="file"] {
	width: 20em;
	height: 1.9em;
	background-color: #fff;
	border: 1px solid #000;
}

input[type="hidden"] {display: none;}

input[type="image"] {margin-bottom: -3px;}

input,textarea {border-radius: 0;}

textarea {
  overflow: auto;
  resize: vertical;
  min-height: 4rem;
}


// Ipad and mobile devices
label, label span {
	vertical-align: top;
	cursor: pointer;
}

/* First letter */
span.first-letter {
	overflow: visible;
	width: 0.600em;
}

button,html [type="button"],[type="reset"],[type="submit"] {-webkit-appearance: button;}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
	padding: 0 !important;
	border: 0 none !important;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {-webkit-appearance: listbox;}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
}

:link,:focus,:active,:hover,:visited {
	outline: none;
	outline-width: 0;
	text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {vertical-align: baseline;}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {height: auto;}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {-webkit-appearance: none;}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {display: inline-block;}

summary {display: list-item;cursor: pointer;}

template {display: none;}

[hidden] {display: none !important;}

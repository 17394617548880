//*******************************************************************************
// 1.0 - POPUP WINDOW
//*******************************************************************************

.popup {
	background-color: rgba(#2e2e31, 0.95);
	position: absolute;
	width: 625px;
	z-index: 2;

	pointer-events: none;
	visibility: hidden;
	opacity: 0;
	@include translate(0, -40px);
	@include transition(visibility 0ms linear 500ms, opacity 250ms $transition_easing_fadeup, transform 500ms $transition_easing_fadeup);

	// Active popup
	&.is-active {
		opacity: 1;
		pointer-events: all;
		visibility: visible;
		@include translate(0, 0);
		@include transition(visibility 0ms linear, opacity 250ms $transition_easing_fadeup, transform 500ms $transition_easing_fadeup);
	}

	// ##################################################


	// Long variant
	&--large { width: 810px; }

	// Wide variant
	&--wide { width: 1078px; }

	// ##################################################

	// White background variant
	&--white {
		background-color: rgba($cgp_white, 0.9);
		//width: 654px;

		.popup-main { padding-bottom: 86px; }

		.popup-column { color: #58585a; }

	}

	// ##################################################

	// Close icon
	&-close {
		cursor: pointer;
		width: 46px;
		height: 46px;
		position: absolute;
		right: 15px;
		bottom: 13px;
		border-radius: 50%;
		z-index: 99;

		pointer-events: none;
		visibility: hidden;
		opacity: 0;
		@include transition(visibility 0ms linear 500ms, opacity 250ms $easing1);

		// Active popup
		&.is-active {
			opacity: 1;
			pointer-events: all;
			visibility: visible;
			@include transition(visibility 0ms linear, opacity 250ms $easing1 250ms);
		}

		// ##################################################

		&:before, &:after {
			@extend %content;
		}

		// Dark variant
		&--dark {

			&:before {
				background: url("data:image/svg+xml,#{$icon_popup_pin_close_bg}") no-repeat scroll center / cover;
			}

			&:after {
				background: url("data:image/svg+xml,#{$icon_popup_pin_close}") no-repeat scroll center / 18px auto;
			}

		}

		// ##################################################

		// White variant
		&--white {

			&:before {
				background: url("data:image/svg+xml,#{$icon_popup_pin_close_bg_white}") no-repeat scroll center / cover;
			}

			&:after {
				background: url("data:image/svg+xml,#{$icon_popup_pin_close_orange}") no-repeat scroll center / 18px auto;
			}

		}

		// ##################################################

	}

	// ##################################################

	// Image
	&-image {}

	// ##################################################

	// Main Content
	&-main {
		padding: 100px 100px 40px 40px;
		display: flex;
		flex-direction: row;
	}

	// ##################################################

	// Icon
	&-icon {
		min-width: calc(44px + 30px);
		width: calc(44px + 30px);
		padding-right: 30px;
	}

	// ##################################################

	// Content
	&-content {
		@include flexbox();
		@include align-items(flex-start);
	}

	// ##################################################

	// Content column(s)
	&-column {
		color: $cgp_white;
		padding-bottom: 8px;
		padding-left: 30px;
		padding-right: 30px;
		position: relative;

		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 2px;
			height: 100%;
			background: linear-gradient(0deg, rgba(225,38,29,1) 0%, rgba(237,110,68,1) 100%);
		}

		&:only-of-type { padding-right: 0; }

		&:not(:only-of-type) { width: 50%; }

		//&:not(:only-of-type):nth-child(2) {}

		// ##################################################

		.page-text {

			p:not(:last-of-type) { margin-bottom: 2em; }

		}

	}

	// ##################################################

	// Tittle
	&-title { line-height: 1em; }

	//&-title + &-text { margin-top: 20px; }

	&-text + .donut-pie-container { margin-top: 40px; }

	// ##################################################

	// Text
	&-text {
		color: inherit;

		// ##################################################

		// Margins
		&:not(:first-of-type) { margin-top: 24px; }

		// ##################################################

		h3 {
			color: $cgp_orange;
			font-size: 24px;
			font-weight: 900;
			line-height: 1em;
			margin-bottom: 0.5em;
		}

		strong { font-weight: 900;	}

		ul {
			margin: 0;
			list-style: none;
		}

		li {
			position: relative;

			&:before {
				background-color: $cgp_orange;
				border-radius: 50%;
				content: "";
				display: inline-block;
				height: 6px;
				width: 6px;
				margin-right: 8px;
				position: relative;
				top: -0.2em;
				@extend %inherit_textstyles;
			}

		}

		// ##################################################

	}

	// ##################################################

	&-text + &-link { margin-top: 20px; }

	// ##################################################

	// Images
	&-image {
		padding-top: 20px;

		&--inline {
			font-size: 0;

			svg, img {
				display: inline-block;

				&:not(:last-of-type) { margin-right: 10px; }

			}

		}

	}

	// ##################################################

	// Link
	&-link {
		color: $cgp_white;
		background-color: #585856;

		border-radius: 4px;
		letter-spacing: 1pt;
		font-weight: 700;
		display: inline-block;
		text-transform: uppercase;
		font-size: 12px;

		padding: 4px 12px;
		position: relative;

		// Gradient border
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			margin: -2px;
			border-radius: inherit;
			background: linear-gradient(to right, #e1261d, #ed6e44);
		}

		&:hover, &:focus { color: $cgp_white; }

	}

	// ##################################################

}





//*******************************************************************************
// 1.1 - POPUP WINDOW - MENU
//*******************************************************************************

$popmenuOffset: 80px;

.popmenu {
	position: absolute;
	right: $popmenuOffset;
	bottom: calc(150px - 24px);
	width: calc(100% - #{2 * $popmenuOffset});
	display: flex;

	pointer-events: none;
	visibility: hidden;
	opacity: 0;
	@include translate (0, 10%);
	@include transition(visibility 0ms linear 500ms, opacity 250ms $transition_easing_fadeup, transform 500ms $transition_easing_fadeup);

	&.is-active {
		pointer-events: all;
		visibility: visible;
		opacity: 1;
		@include translate(0,0);
		@include transition(visibility 0ms linear, opacity 250ms $transition_easing_fadeup, transform 500ms $transition_easing_fadeup);
	}

	// ##################################################

	// Border square for menu item
	&:before {
		background-color: rgba(#2e2e31, 0.96);
		content: "";
		display: block;
		position: absolute;
		width: 110px;
		height: 110px;
		right: 0;
		bottom: 0;
		@include translate(0, 100%);
	}

	// ##################################################
	// Image container

	&-image {
		background: black no-repeat scroll center / cover;
		flex-grow: 1;

		opacity: 0;
		@include translate (-25%, 0);
		@include transition(opacity 250ms $transition_easing_fadeup, transform 300ms $transition_easing_fadeup);

		&.is-active {
			opacity: 1;
			@include translate(0,0);
			@include transition(opacity 250ms $transition_easing_fadeup 50ms, transform 300ms $transition_easing_fadeup);
		}

	}

	// ##################################################
	// Main content

	&-main {
		background-color: rgba(#2e2e31, 0.96);
		padding: 140px 60px 180px;
		width: calc(100% - 614px);
		margin-left: auto;
	}

	// ##################################################

		// Close icon
	&-close {
		cursor: pointer;
		width: 46px;
		height: 46px;
		position: absolute;
		right: 14px;
		top: 13px;
		border-radius: 50%;
		z-index: 2;

		// ##################################################

		&:before, &:after {
			@extend %content;
		}

		&:before {
			background: url("data:image/svg+xml,#{$icon_popup_pin_close_bg}") no-repeat scroll center / cover;
		}

		&:after {
			background: url("data:image/svg+xml,#{$icon_popup_pin_close}") no-repeat scroll center / 18px auto;
		}

	}

	// ##################################################

	&-title {
		line-height: 1em;
		margin-bottom: 50px;
		padding-left: 60px;
	}

	// ##################################################
	// Content for columns

	&-content {

		// ##################################################

		&-nav {
			display: flex;
		}

		// ##################################################

		ul {
			padding-top: 2px;
			padding-bottom: 2px;
			margin: 0;
			position: relative;

			&:before {
				background: linear-gradient(0deg, #e1261d 0%, #ed6e44 100%);
				width: 2px;
				@extend %content;
			}

		}

		// ##################################################

		li {}

		// ##################################################

	}


	// ##################################################
	// ##################################################

	&-col {
		padding-left: 70px;
		width: 100%;


		// ##################################################

		&-title, &-item {
			padding-left: 35px;
		}

		// ##################################################

		&-title {
			display: block;
			margin-bottom: 20px;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 1.5pt;
		}

		// ##################################################

		&-icon {
			position: absolute;
			left: 0;
			top: 0;
			width: 70px;
			height: 70px;
			padding-right: 18px;

			@include flexbox();
			@include align-items(flex-start);
			@include translate(-100%, 0);

		}

		// ##################################################

		&-item {
			color: $cgp_white;
			font-size: 18px;
			line-height: 1.33em;
			font-weight: 300;
			padding-top: 5px;
			padding-bottom: 3px;
			position: relative;

			// Bullet
			&:before {
				content: "•";
				color: $cgp_orange;
				margin-right: 0.5em;
				font-weight: 700;
				@extend %inherit_textstyles;
			}

			// ##################################################

			&--image {
				cursor: pointer;

				// Photo icon
				&:after {
					background: url("data:image/svg+xml,#{$icon_photo_white}") no-repeat scroll left 10px center / 16px auto;
					color: white;
					@extend %content;
				}

				&.is-active, &:hover {
					color: $cgp_orange;
					background-color: rgba($cgp_black, 0.2);
					font-weight: 700;

					&:after { background-image: url("data:image/svg+xml,#{$icon_photo_orange}"); }

				}

			}

		}

		// ##################################################

		//&-item + &-item {margin-top: 8px;}

		// ##################################################

	}

	// ##################################################

}





//*******************************************************************************
// 2.0 - POPUP PINS
//*******************************************************************************

.popup-pin {
	border: 2px solid #de3128;
	height: 100px;
	width: 100px;
	position: absolute;
	border-radius: 100%;

	@include scale(1);
	@include transition(transform 250ms ease-in);

	// ##################################################

	&:hover {
		@include scale(1.25);

		&:before { opacity: 0.75; }

	}

	// ##################################################

	&, & * {cursor: pointer;}

	// ##################################################

	// Glow
	&:before {
		@extend %content;
		background: url("data:image/svg+xml,#{$popup_pin_glow}") no-repeat scroll center / cover;
		pointer-events: none;
		z-index: 0;
		opacity: 0;
		filter: blur(10px);
		@include scale(1.5);
		@include transition(opacity 250ms ease-in);

		// On subpage make the gradient black
		.show-subpage & {
			opacity: 0.66;
			filter: invert(1);
		}

	}

	// ##################################################

	&-bg {
		background: url("data:image/svg+xml,#{$popup_pin}") no-repeat scroll center / cover;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 2;
	}

	// ##################################################

	&-image {
		position: absolute;
		z-index: 2;
		display: flex;
		padding: 24px;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;

		img, svg { margin: auto; }
	}

	// ##################################################

	&-text {
		color: $cgp_white;
		font-size: 15px;
		font-weight: 700;
		line-height: 1.25em;
		text-transform: uppercase;

		height: 100%;
		padding-left: 12px;
		display: flex;
		align-items: center;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;

		@include translate(100%, 0);

		// ##################################################

		* {

			// Make the text with black shadow
			.show-subpage & {
				filter: drop-shadow(0px 0px 5px $cgp_black);
				text-shadow: 0 0 20px $cgp_black;
			}
		}

		// ##################################################

	}

	// Text on left side
	&--text-left &-text {
		padding-left: 0;
		padding-right: 12px;
		text-align: right;
		@include translate(-100%, 0);
	}

	// ##################################################

}

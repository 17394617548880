//*******************************************************************************
// 0.0 - GENERAL
//*******************************************************************************

.form-title {}

//Form group
.form-general {

	input:not([type="submit"]),
	textarea,
	.faker .drop-value {
		font-size: 16px;
		height: 2em;
		width: 100%;
		padding: 0 .5em;

		// ##################################################

		&:focus {}
		
		// ##################################################

	}

	// ##################################################
	
	// Textare
	textarea {
		padding: .5em;
	}

	// Submit button
	input[type="submit"],
	button {
		border: none;
		height: 2em;
		padding: .5em;

		span {cursor: inherit;}

	}

	// ##################################################

	//@include placeholder(rgba($cgp_black,1));

	// ##################################################
}




// ##################################################
// ##################################################




.form-label {}

// General form
.general-form {

	.text {}

	// Form separator
	.form-separator {}

	// Form groups
	.form-group {}

	.input {}

	// Required text
	.required {
		small {}
	}

	// Fakers
	.faker .drop-value {}

	// Submit
	.btn-submit {}


}

// terms
.terms {

	p, label {}

	label {}
	input {
		display: none;
		&:checked ~ label:before {opacity: 1}
	}
	p {
		display: inline;
		a {
			&:hover {text-decoration: underline;}
		}
	}
}

// Success text
.success-text {
	@extend %page_text !optional;
}




//*******************************************************************************
// 0.0 - TERMS CHECKBOX
//*******************************************************************************

// CHECKBOX for terms
.form-checkbox	{

	// Desktop
	@include bp-between(lg,xl) { max-width: 55%; }

	// Tablet L
	@include bp-only(md) {
		margin-bottom: 12px;
		max-width: 70%;
	}

	// Tablet P
	@include bp-only(sm) { max-width: 65%; }

	// Mobile
	@include bp-only(xs) {margin-bottom: 24px;}

	// ##################################################

	// Input
	input {display: none;}

	// ##################################################

	// Label
	&-label {
		position: relative;
		display: block;
		float: left;
	}

	// ##################################################

	// Text
	span {
		@extend %fontsize16 !optional;

		color: $cgp_black;
		font-family: $font_secondary;
		font-weight: 400;
		cursor: pointer;
		display: block;
		position: relative;
		padding-left: calc(24px + 18px);
		z-index: 9;

		// ##################################################

		.dark-gray-bg &{
			color: $cgp_white;
			a{
				color: inherit;
				text-decoration: underline;
			}
		}

		// ##################################################

		a {
			@extend %inherit_textstyles;
			color: $cgp_black;
		}

		// ##################################################

		&:before, &:after {
			@extend %content;
			height: 24px;
			width: 24px;
		}

		// ##################################################

		// Frame
		&:before	{
			border: 1px solid #979797;
		}

		// ##################################################

		// Square
		&:after	{
			background-color: $cgp_black;

			transform: scale(0);
			transition: transform 0.2s ease-in-out;

			// ##################################################

			.dark-gray-bg &{
				background-color: $cgp_white;
			}
		}
	}

	// ##################################################

	// Checkbox checked
	input:checked + span {
		&:after	{ @include scale(0.6); }
	}

}






//*******************************************************************************
// 1.0 - NEWSLETTER FORM
//*******************************************************************************

// Enews form
.form-enews {

	// ##################################################

	// Content
	&-content {}

	// ##################################################

	.form-group {}



	// ##################################################

	input[type="email"] {

		// ##################################################

	}

	// ##################################################

	.form-footer {
		//padding-top: 16px;
		margin-bottom: 0;

		// Tablet P and up
		@include bp-up(sm) {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}

	}

	// ##################################################

	// Validation
	.form-invalid {

		input[type="email"] {
			color: #d0021b;
			border: 1px solid currentColor;
		}

	}

	// ##################################################

	// Form sent
	.form-sent-container {
		display: none;


		// ##################################################

		// title
		.title {
			@extend %heading_h3 !optional;
		}

		// ##################################################

		// Text
		.text {}

	}

	.form-sent-success {

		.form-group {display: none;}

		.form-sent-container {display: block;}

	}

}
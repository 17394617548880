//*******************************************************************************
// 0 - GENERAL
//*******************************************************************************

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Text selection styling
//@include selection($cgp_orange, $cgp_white);

// Content wrapper, used flex for sticky footer
body {
	flex: 1 0 auto;
	position: relative;
	z-index: 1;
	font-size: 14px; // Audit legibility recommendation
	//overflow: hidden; // Causes bottom section to not be shown on Safari!
}

.main {
	flex: 1 0 auto;
	//min-height: 100vh;
}

// Modal window - prevent scrolling
html.#{$class_open_menu},
html.#{$class_open_menu} body,
body.#{$class_open_menu},
html.#{$class_open_search},
html.#{$class_open_search} body,
body.noscroll {

	// For desktop we need this, so we hide the scrollbars effectively (page jumps to top)
	@media screen and (min-width: #{$bkp_lg + 1}) {
		// overflow-x: hidden;
		// overflow-y: scroll !important;
		overflow: hidden; // To prevent double scrollbar in menu [menu scroll bar + body scroll bar]
		position: fixed;
	}

	// For mobile we only need hidden, so the page doesn't jump on top
	@include bp-down(lg) {overflow: hidden;}
}

// Body
body {
	//background-color: $cgp_light_blue;
	color: $cgp_black;
	min-width: $bkp_xs;
	font-family: $font_primary;
	position: relative;
	height: 100%;
	width: 100%;

	overflow: hidden;

	display: flex;
	flex-direction: column;
}

// Prevents text selection styling
.noselect, .noselect * {
	user-select: none;
}

// .no-margin { margin: 0 }
// .no-padding { padding: 0 }



//*******************************************************************************
// 0.2 - GENERAL : Text styles
//*******************************************************************************

.text, .title, .title-main {
	h1, h2, h3, h4, h5, h6, p, span, ul, li, a { @extend %inherit_textstyles; }
}

// Default cursor for text types
p, span, em, strong, div {cursor: default;}

// Paragraphs
p {
	a {color: inherit;}
}


// Links
a {color: black;}

a[href] * {cursor: pointer;}

a:hover, a:focus {
	color: currentColor;
	text-decoration: none;
}


//*******************************************************************************
// 0.3 - GENERAL : Images
//*******************************************************************************

// General image
img {
	display: block;
	position: relative;
	margin: 0;
	max-height: 100%;
	max-width: 100%;
	height: auto;
	width: auto;
}

// General image wrapper
.image {}

@keyframes placeholderShimmer{
	0% {background-position:-1200px 0}
	100% {background-position:1200px 0}
}

// Aspect ratio hacks for keeping image wrapper in correct spect ratio for image
// Could be improved with added class when image is loaded and then transitioning it to opacity 1;
[style*="--aspect-ratio"] > :first-child {width: 100%;}
[style*="--aspect-ratio"] > img {
	height: auto;
	//opacity: 0;
	//@include transition(opacity 250ms ease-in);
}

@supports (--custom:property) {

	[style*="--aspect-ratio"] {position: relative;}

	// Placeholder image for unloaded images
	[style*="--aspect-ratio"]::before {
		background: linear-gradient(to right,rgba(0,0,0,.08) 0,rgba(0,0,0,.15) 15%,rgba(0,0,0,.08) 30%);
		background-size: 1200px 100%;
		animation: placeholderShimmer 5s linear infinite;

		content: "";
		display: block;
		padding-bottom: calc(100% / (var(--aspect-ratio)));
		position: relative;
		z-index: -1;
	}

	// Placeholder image for unloaded images
	[style*="--aspect-ratio"] > :first-child {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;

		&:before {
			background: no-repeat scroll center url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23DADADA' d='M0 0h200v200H0z'/%3E%3Cpath d='M26.653 14.92h145.834a11.715 11.715 0 0 1 11.734 11.733v77.736l-33.735-42.534c-3.98-5.239-11.943-5.239-15.924 0l-49.24 62.23-20.534-22.42c-4.19-4.61-11.524-4.61-15.715 0L14.92 138.754v-112.1A11.715 11.715 0 0 1 26.653 14.92z' fill='%23FFF' fill-rule='nonzero'/%3E%3Ccircle fill='%23DADADA' fill-rule='nonzero' cx='51.378' cy='51.378' r='16.763'/%3E%3C/g%3E%3C/svg%3E") / auto 50%;
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
		}

	}

}

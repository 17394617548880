//*******************************************************************************
// PLACEHOLDERS
//*******************************************************************************

// General extensions
%content {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}


// ##################################################
// ##################################################


%readmore {
	position: relative;

	&:hover, &:active, &:focus {}

}





//*******************************************************************************
// 0.0 - MARGINS AND PADDINGS
//*******************************************************************************


// Elements margin bottom
%element_margin_bottom {
	@include bp-only(xl) {margin-bottom: $el_margin_bottom_xl;}
	@include bp-only(lg) {margin-bottom: $el_margin_bottom_lg;}
	@include bp-only(md) {margin-bottom: $el_margin_bottom_md;}
	@include bp-only(sm) {margin-bottom: $el_margin_bottom_sm;}
	@include bp-only(xs) {margin-bottom: $el_margin_bottom_xs;}
}


// ##################################################
// ##################################################


// Section margins
%section_margins {
	@include bp-only(xl) {margin-bottom: $section_margin_xl;}
	@include bp-only(lg) {margin-bottom: $section_margin_lg;}
	@include bp-only(md) {margin-bottom: $section_margin_md;}
	@include bp-only(sm) {margin-bottom: $section_margin_sm;}
	@include bp-only(xs) {margin-bottom: $section_margin_xs;}
}





//*******************************************************************************
// FONT SIZES
//*******************************************************************************

// ##################################################

// General page text - Paragraph
%page_text {

	// Desktop XL
	@include bp-between(lg,xl) {font-size: 21px;}

	// Desktop
	@include bp-down(md) {font-size: 18px;}
}



// General page text - Paragraph
%page_text_small {

	// Desktop XL
	@include bp-between(lg,xl) {font-size: 18px;}

	// Desktop
	@include bp-down(md) {font-size: 16px;}
}

// ##################################################
// ##################################################


%heading {
	font-family: $font_primary;
}

// H0
%heading_h0 {
	@extend %heading;
	line-height: 1em;

	// Desktop
	@include bp-between(lg,xl) {font-size: 80px;}

	// Tablet
	@include bp-between(sm,md) {font-size: 50px;}

	// Mobile
	@include bp-only(xs) {font-size: 34px;}

}

// ##################################################

// H1
%heading_h1 {
	@extend %heading;
	line-height: 1em;

	// Desktop XL
	@include bp-only(xl) {font-size: 98px;}

	// Desktop
	@include bp-only(lg) {font-size: 80px;}

	// Tablet
	@include bp-between(sm,md) {font-size: 30px;}

	// Mobile
	@include bp-only(xs) {font-size: 28px;}

}

// ##################################################

// H2
%heading_h2 {
	@extend %heading;
	line-height: 1.25em;

	// Desktop XL
	@include bp-only(xl) {font-size: 56px;}

	// Desktop
	@include bp-only(lg) {font-size: 30px;}

	// Tablet
	@include bp-between(sm,md) {font-size: 26px;}

	// Mobile
	@include bp-only(xs) {font-size: 24px;}

}

// ##################################################

// H3
%heading_h3 {
	@extend %heading;
	line-height: 1.25em;

	// Desktop XL
	@include bp-only(xl) {font-size: 22px;}

	// Desktop
	@include bp-only(lg) {font-size: 20px;}

	// Tablet
	@include bp-between(sm,md) {font-size: 24px;}

	// Mobile
	@include bp-only(xs) {font-size: 22px;}

}

// ##################################################

// H4
%heading_h4 {
	@extend %heading;

	// Desktop XL
	@include bp-only(xl) {font-size: 24px;}

	// Desktop
	@include bp-only(lg) {font-size: 22px;}

	// Tablet
	@include bp-down(md) {font-size: 20px;}

}

// ##################################################

// H5
%heading_h5 {
	@extend %heading;
	line-height: 1.3em;

	// Desktop XL
	@include bp-between(lg,xl) {font-size: 20px;}

	// Desktop
	@include bp-down(md) {font-size: 18px;}

}

// ##################################################

// H6
%heading_h6 {
	@extend %heading;
	line-height: 1.3em;
	font-size: 16px;
}


// ##################################################
// ##################################################




// ##################################################
// ##################################################


%inherit_textstyles {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	text-transform: inherit;
	text-align: inherit;
	font-style: inherit;
}

///*******************************************************************************
// 1.0 MENU MAIN
///*******************************************************************************

$menu_elements: 8;
$dropdown_anim_delay_menu_item: 200ms;



// Main menu
.menu {
	z-index: $zindex_menu;
	color: $cgp_gray;
	background-color: rgba(#676769, 0.96);

	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	padding: 188px 25px 168px;

	opacity: 0;
	pointer-events: none;
	overflow: auto;
	@include scale(0.94);
	@include transition(opacity 300ms ease, transform 300ms ease);

	// Tablet
	@include bp-between(sm, md) {
		padding: 180px 25px 80px;
	}

	// Tablet P and down
	@include bp-down(sm) {
		line-height: 1.4;
	}

	// Mobile
	@include bp-only(xs) {
		padding: 90px 0 30px;
		background-color: rgba(#676769, 1);
	}

	.#{$class_open_menu} & {
		opacity: 1;
		pointer-events: auto;
		@include scale(1);
	}

	// ##################################################

	strong {
		font-weight: 700;
	}

	// ##################################################

	.container {
		max-width: 1620px;
		position: relative;
	}

	// ##################################################

	&-description {
		position: relative;
		max-width: 380px;
		font-weight: 300;
		color: #2e2e31;
		font-size: 27px;

		// Desktop Normal
		@include bp-only(lg) {
			font-size: 18px;
			line-height: 1.4;
		}

		// Tablet L
		@include bp-only(md) {
			font-size: 24px;
		}

		// Tablet P
		@include bp-only(sm) {
			font-size: 18px;
		}

		// Mobile
		@include bp-only(xs) {
			font-size: 14px;
			margin-bottom: 20px;
			max-width: 100%;
		}

		// ##################################################

		p {
			margin: 0;
		}

		b,
		strong {
			font-weight: 700;
		}
	}

	// ##################################################

	&-list {
		list-style: none;
		padding-left: 10px;
		margin-bottom: 0;
		position: relative;

		// ##################################################

		// Left border [Desktop only]
		&::before {
			content: '';
			position: absolute;
			left: 9px;
			top: 0;
			width: 2px;
			height: 100%;
			background-color: #e96650;
			background-image: linear-gradient(180deg, #e96650 0%, $cgp_pink 100%);
			transform: scaleY(0);

			.#{$class_open_menu} & {
				transform: scaleY(1);
				@include transition(transform 300ms ease-in-out #{$menu_elements * $dropdown_anim_delay_menu_item / 2 });
			}

			// Mobile
			@include bp-only(xs) {
				display: none;
			}
		}

		// ##################################################

		&-wrapper {
			margin-top: 45px;
			position: relative;

			// Mobile
			@include bp-only(xs) {
				margin-top: 0;
			}

			// ##################################################

			// Left border
			&::before {
				content: '';
				position: absolute;
				left: 9px;
				top: 0;
				width: 2px;
				display: none;
				height: 100%;
				background-color: #e96650;
				background-image: linear-gradient(180deg, #e96650 0%, $cgp_pink 100%);
				transform: scaleY(0);

				.#{$class_open_menu} & {
					transform: scaleY(1);
					@include transition(transform 300ms ease-in-out #{$menu_elements * $dropdown_anim_delay_menu_item / 2 });
				}

				// Mobile
				@include bp-only(xs) {
					display: block;
				}
			}
		}

		// ##################################################

		&-item {

			// Tablet P and up
			@include bp-up(sm) {
				opacity: 0;
				@include translate(0, -30px);
			}

			// Mobile
			@include bp-only(xs) {
				display: inline-block;
				width: 100%;
			}

			// ##################################################

			.#{$class_open_menu} & {

				// Tablet P and up
				@include bp-up(sm) {
					opacity: 1;
					@include translate(0, 0);
					@include transition(opacity 300ms ease, transform 300ms ease);


					// ##################################################

					@for $i from 0 to $menu_elements {
						&:nth-child(#{$i}) {
							transition-delay: ($dropdown_anim_delay_menu_item * $i / 2);
						}
					}
				}
			}

			// ##################################################

			&+.menu-list-item {

				// Desktop
				@include bp-up(lg) {
					margin-top: 25px;
				}

				// Tablet
				@include bp-between(sm, md) {
					margin-top: 15px;
				}
			}
		}
	}

	// ##################################################

	&-link {
		padding: 18px 0 17px 31px;
		display: block;
		position: relative;
		text-transform: uppercase;
		color: $cgp_white;
		font-size: 32px;
		font-weight: 400;
		letter-spacing: 6.4px;
		@include transition(color 0.3s ease);

		// Desktop Normal
		@include bp-only(lg) {
			font-size: 28px;
			letter-spacing: 5px;
		}

		// Tablet L
		@include bp-only(md) {
			font-size: 24px;
			letter-spacing: 2px;
			padding: 10px 0 10px 30px;
		}

		// Tablet P
		@include bp-only(sm) {
			font-size: 20px;
			padding-left: 30px;
			letter-spacing: 1px;
			padding: 10px 0 10px 30px;
		}

		// Mobile
		@include bp-only(xs) {
			font-size: 18px;
			letter-spacing: 2px;
			display: block;
			padding: 10px 20px;
		}

		// ##################################################

		// Circle
		&::before {
			content: '';
			width: 19px;
			height: 19px;
			position: absolute;
			left: 0;
			top: 50%;
			background: #676769 url("data:image/svg+xml,#{$icon_menu_cricle}") no-repeat center center / contain;
			opacity: 0;
			@include transform(translate(-50%, -50%) scale(0.7));
			@include transition(opacity 0.3s ease, transform 0.3s ease);

			// Mobile
			@include bp-only(xs) {
				width: 14px;
				height: 14px;
			}
		}

		// ##################################################

		&:hover,
		&:focus,
		&.#{$class_active} {
			color: #e96650;

			&::before {
				opacity: 1;
				@include transform(translate(-50%, -50%) scale(1));
			}
		}

		&.#{$class_active} {
			font-weight: 700;
		}
	}
}





///*******************************************************************************
// 2.0 MENU SECONDARY
///*******************************************************************************

.menu-secondary {
	position: absolute;
	top: 100%;
	right: 0;
	padding: 0 80px 40px 40px;
	display: flex;
	align-items: center;
	pointer-events: none;

	@include translate(0, -100%);
	@include transition(transform 1s $transition_easing_fadeup, top 1s $transition_easing_fadeup, right 1s $transition_easing_fadeup);

	// ##################################################

	.page--homepage & {
		right: 50%;
		@include translate(50%, -100%);
	}

	// ##################################################

	// Subpage active
	.show-subpage & {
		top: 40px;
		z-index: 10;
	}

	.page--main.show-subpage & {
		@include translate(0, 0);
	}

	.page--homepage.show-subpage & {
		@include translate(100%, 0);
	}

	.is-open-menu & { z-index: 1; }

	// ##################################################

	ul {
		margin: 0;
		display: flex;
		align-items: center;
	}

	// ##################################################

	li {}

	// ##################################################

	&-item {
		margin-left: 40px;
		margin-right: 40px;
		position: relative;
		z-index: 1;

		// Top / Bottom borders
		&:before {
			background-image: linear-gradient(to right, #e1261d 0%, #ed6e44 100%);
			content: "";
			display: block;
			width: 100%;
			height: 1.9px;
			left: 0;
			top: 0;
			position: absolute;
			@include transition(opacity 0.25s $transition_easing_fadeup);

			.show-subpage & {
				transition-delay: 0s;
				transition-duration: 0.5s;
			}

		}

		// ##################################################

		// Active state
		&.is-active {
			z-index: 2;

			&:before { opacity: 0; }

			// ##################################################

			.menu-secondary-link-primary { opacity: 0; }
			.menu-secondary-link-secondary  {opacity: 1; }

			// ##################################################

		}
	}

	// ##################################################

	&-link {
		color: $cgp_white;
		display: block;
		text-transform: uppercase;
		line-height: 1em;
		font-weight: 700;
		font-size: 22px;
		padding: 12px 46px;
		pointer-events: all;
		position: relative;
		//overflow: hidden;
		text-align: center;
		z-index: 2;

		background-image: linear-gradient(to right, transparent 0%, transparent 100%);
		@include transition(all 250ms $transition_easing_fadeup);

		&:hover, &:focus { color: $cgp_white; }

		// ##################################################

		p, span {
			display: block;
			white-space: nowrap;
		}

		p {
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 2.8px;
		}

		// ##################################################

		// Inside text of a link
		&-primary,
		&-secondary {
			pointer-events: none;
			@include transition(opacity 250ms $transition_easing_fadeup);
		}

		&-primary { opacity: 1; }

		&-secondary {
			opacity: 0;
			position: absolute;
			left: 50%;
			top: 50%;
			@include translate(-50%, -50%);

			p, span {
				display: block;
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 2pt;
				line-height: 1;
			}

		}

		// ##################################################

		// Inside text, switches between more/back
		// .menu-secondary-text {
		// 	position: relative;
		// 	@include transition(opacity 0.5s $transition_easing_fadeup);

		// 	&--more,
		// 	&--back {
		// 		@include transition(opacity 0.5s $transition_easing_fadeup);
		// 	}

		// 	&--more {
		// 		opacity: 1;

		// 		.show-subpage & {opacity: 0;}

		// 	}

		// 	&--back {
		// 		position: absolute;
		// 		left: 0;
		// 		top: 0;
		// 		width: 100%;
		// 		opacity: 0;

		// 		.show-subpage & {opacity: 1;}

		// 	}

		// }

		// ##################################################

		// Top / Bottom borders
		// &:after {
		// 	background-image: linear-gradient(to right, #e1261d 0%, #ed6e44 100%);
		// 	content: "";
		// 	display: block;
		// 	width: 100%;
		// 	height: 0.9px;
		// 	bottom: 0;
		// 	left: 0;
		// 	position: absolute;
		// }

		.submenu-line-effect {
			bottom: 0;
			left: 50%;
			position: absolute;
			@include translate(-50%,0);
			@include transition(transform 0.25s $transition_easing_fadeup 0.25s);

			.show-subpage .menu-secondary-item.is-active & {
				@include translate(-50%,-44px);
			}

			.show-subpage & {
				transition-delay: 0.25s;
				transition-duration: 0.25s;
			}

		}

	}

	// ##################################################
	// Submenu popup

	.popup {
		left: 50%;
		bottom: -46px;
		transform: translate(-50%,100px);
		z-index: 1;

		&.is-active {
			transform: translate(-50%,0);
		}

		// ##################################################

		&-main {
			padding-bottom: 300px;
		}

		// ##################################################

		&-icon {
			min-width: calc(62px + 30px);
			width: calc(62px + 30px);
		}

		// ##################################################

		&-close {
			top: 10px;
			right: 13px;
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}

	}

	// ##################################################
	// Icon (for homepage)

	&-icon {
		position: absolute;
		top: 0;
		width: 100%;
		padding-bottom: 14px;

		opacity: 1;
		pointer-events: all;
		visibility: visible;

		@include flexbox();
		@include justify-content(center);
		@include translate(0, -100%);
		@include transition(visibility 0ms linear, opacity 250ms $transition_easing_fadeup, transform 300ms $transition_easing_fadeup);

		// ##################################################

		.show-subpage &,
		.page--main & {
			opacity: 0;
			pointer-events: none;
			visibility: hidden;
			@include transition(visibility 0ms linear 500ms, opacity 250ms $transition_easing_fadeup, transform 300ms $transition_easing_fadeup);
		}

		// ##################################################

		//img, svg {}

	}

	// ##################################################

}



// ##################################################
// ##################################################

.nav-support {
	cursor: pointer;
	padding-left: 20px;
	padding-right: 30px;
	position: relative;
	opacity: 1;
	pointer-events: all;

	@include transition(opacity 500ms $transition_easing_fadeup);

	// Subpage active
	.show-subpage & {
		opacity: 0;
		pointer-events: none;
	}

	.page--homepage & { display: none; }

	// ##################################################

	// Active and hover state
	&.is-active, &:hover {

		svg defs stop {
			&:first-child { stop-color: #ffffff; }
			&:last-child { stop-color: #ffffff; }
		}

	}

	// ##################################################

	// SVG path icon
	svg {
		width: 62px;
		position: relative;
		top: -4px;

		// ##################################################

		.nav-support-path--small { fill: url(#submenu-gradient--small); }
		.nav-support-path--big { fill: url(#submenu-gradient--big); }

		defs stop {
			@include transition(all 0.5s $transition_easing_fadeup);

			//&:first-child { stop-color: #f90f10; }
			//&:last-child { stop-color: #ff5342; }

		}


	}


}






//**********************************************************
// MENU HAMBURGER
//**********************************************************

$dot_width: 9px;
$dot_width_xs: 5px;

.menu-hamburger {
	width: 40px;
	height: 30px;
	margin-top: 5px;
	border: 0;
	background: transparent;
	position: relative;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	// Mobile
	@include bp-only(xs) {
		width: 26px;
		height: 26px;
	}

	// ##################################################

	&-label {
		color: $cgp_white;
		font-size: 15px;
		font-weight: 400;
		letter-spacing: 3px;
		position: absolute;
		top: 50%;
		white-space: nowrap;
		padding-right: 28px;
		opacity: 0;
		pointer-events: none;
		@include translate(-100%, -50%);
		@include transition(opacity 0.2s ease);

		.#{$class_open_menu} & {
			opacity: 1;
			pointer-events: auto;
		}

		// Mobile
		@include bp-only(xs) {
			letter-spacing: 1px;
			font-size: 12px;
			padding-right: 6px;
		}

		// ##################################################

		// Circle around X sign
		&::before {
			content: '';
			position: absolute;
			width: 47px;
			height: 47px;
			right: -43px;
			top: 50%;
			background:url("data:image/svg+xml,#{$icon_menu_x_cricle}") no-repeat center center / contain;
			@include translate(0, -50%);

			// Mobile
			@include bp-only(xs) {
				width: 30px;
				height: 30px;
				right: -28px;
			}
		}
	}

	// ##################################################

	// Dots
	&:after,
	&:before,
	span {
		width: $dot_width;
		height: $dot_width;
		background-color: $cgp_header_items;
		border-radius: 10px;
		position: absolute;
		left: 1%;
		right: 1%;
		margin: 0 auto;
		@include transition(all 0.4s ease);

		// Mobile
		@include bp-only(xs) {
			width: $dot_width_xs;
			height: $dot_width_xs;
		}
	}

	// ##################################################

	&:after,
	&:before {
		content: "";
	}

	// ##################################################

	&:after {
		top: -#{$dot_width/2};
		margin-top: 0;

		// Mobile
		@include bp-only(xs) {
			top: -#{$dot_width_xs/2};
		}
	}

	// ##################################################

	&:before {
		bottom: -#{$dot_width/2};
		margin-bottom: 0;

		// Mobile
		@include bp-only(xs) {
			bottom: -#{$dot_width_xs/2};
		}
	}

	// ##################################################

	span {
		top: 50%;
		margin-top: -#{$dot_width/2};

		// Mobile
		@include bp-only(xs) {
			margin-top: -#{$dot_width_xs/2};
		}
	}

	// ##################################################

	.#{$class_open_menu} & {

		&::before,
		&::after {
			height: 1px;
			background-color: $cgp_white;
		}

		// ##################################################

		&:after {
			width: 24px;
			@include transform(rotate(135deg) translate(12px, -12px));

			// Mobile
			@include bp-only(xs) {
				width: 18px;
				@include transform(rotate(135deg) translate(10px, -10px));
			}
		}

		// ##################################################

		&:before {
			transform: rotate(225deg);
			bottom: 50%;
			width: 24px;

			// Mobile
			@include bp-only(xs) {
				width: 18px;
			}
		}

		// ##################################################

		span {
			opacity: 0;
		}
	}
}





//**********************************************************
// SCREEN READER
//**********************************************************

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}
